import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function YouTubeIcon(props: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59611 5.69772C3.7306 5.87697 1.42989 8.10629 1.20507 10.9686C1.06737 12.7216 0.952393 14.6144 0.952393 16.0473C0.952393 17.4819 1.06765 19.3776 1.20557 21.1324C1.4303 23.9918 3.72727 26.2199 6.58984 26.3996C9.45959 26.5798 13.0696 26.7616 15.9524 26.7616C18.8352 26.7616 22.4452 26.5798 25.3149 26.3996C28.1775 26.2199 30.4745 23.9918 30.6992 21.1324C30.8371 19.3776 30.9524 17.4819 30.9524 16.0473C30.9524 14.6144 30.8374 12.7216 30.6997 10.9686C30.4749 8.10629 28.1742 5.87697 25.3087 5.69772C22.4143 5.51666 18.7812 5.33301 15.9524 5.33301C13.1235 5.33301 9.49048 5.51666 6.59611 5.69772ZM20.0916 16.6064C20.4963 16.3435 20.4963 15.7511 20.0916 15.4882L13.4108 11.1489C12.9673 10.8608 12.381 11.1791 12.381 11.708V20.3866C12.381 20.9155 12.9673 21.2338 13.4108 20.9457L20.0916 16.6064Z"
        fill="currentColor"
      />
    </svg>
  );
}
