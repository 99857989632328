export const calendarRoutes = [
  /**
   * Calendar
   */
  {
    path: ':organizationId/:channelUcid/calendar',
    async lazy() {
      const { Calendar } = await import('./calendar');
      return {
        Component: Calendar,
      };
    },
  },
];
