import {
  forwardRef,
  type ChangeEvent,
  type CSSProperties,
  type ForwardedRef,
  type InputHTMLAttributes,
  type PropsWithChildren,
} from 'react';
import clsx from 'clsx';
import * as Styles from './text-input.css';

/**
 * --------------------------------------------------------
 * TextInput Root
 * --------------------------------------------------------
 */
export type TextInputRootProps = PropsWithChildren &
  Styles.TextInputVariants & {
    className?: string;

    /**
     * Puts the input itself into an "errored" visual state
     */
    hasError?: boolean;
    /**
     * On click callback
     */
    onClick?: () => void;

    /**
     * On click callback
     */
    onBlur?: () => void;

    /**
     * On focus callback
     */
    onFocus?: () => void;
  };

export const Root = forwardRef(
  (props: TextInputRootProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      border = 'none',
      bottomLeftCorner,
      bottomRightCorner,
      children,
      className,
      fill = 'solid',
      hasError,
      radii = 'sm',
      size = 'md',
      topLeftCorner,
      topRightCorner,
      variant = 'dark',
      onClick,
      onFocus,
      onBlur,
      weight,
    } = props;

    return (
      <div
        className={clsx(
          Styles.styleVariants({
            border,
            bottomLeftCorner,
            bottomRightCorner,
            topLeftCorner,
            topRightCorner,
            error: hasError,
            fill,
            radii,
            size,
            variant,
            weight,
          }),
          className
        )}
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

Root.displayName = 'TextInputRoot';

/**
 * --------------------------------------------------------
 * TextInput Container
 * --------------------------------------------------------
 */
export type TextInputContainerProps = PropsWithChildren & {
  className?: string;
};

export const Container = (props: TextInputContainerProps) => {
  const { className, children } = props;
  return <div className={clsx(Styles.container, className)}>{children}</div>;
};

Container.displayName = 'TextInputContainer';

/**
 * --------------------------------------------------------
 * TextInput Label
 * --------------------------------------------------------
 */
export type TextInputLabelProps = PropsWithChildren & {
  className?: string;
  id: string;
};

export const Label = (props: TextInputLabelProps) => {
  const { children, className, id } = props;
  return (
    <label htmlFor={id} className={clsx(Styles.label, className)}>
      {children}
    </label>
  );
};

Label.displayName = 'TextInputLabel';

/**
 * --------------------------------------------------------
 * TextInput Input
 * --------------------------------------------------------
 */
export type TextInputInputProps = PropsWithChildren &
  Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'onBlur' | 'onChange' | 'value'
  > & {
    /**
     * Id is required if a label is present
     */
    id?: string;

    /**
     * Callback to get the input's value as a string on `blur`.
     *
     * Optionally provides the entire event just in case you need it.
     */
    onBlur?: (value: string) => void;

    /**
     * Callback to get the input's value as a string on `change`.
     *
     * Optionally provides the entire event just in case you need it.
     */
    onChange?: (value: string) => void;
    /**
     * Value
     */
    value?: string;
  };

export const Input = forwardRef(
  (props: TextInputInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      children,
      className,
      disabled,
      id,
      value,
      onBlur,
      onChange,
      onFocus,
      type = 'text',
      ...inputProps
    } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      if (onChange) {
        onChange(value);
      }
    };

    const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      if (onBlur) {
        onBlur(value);
      }
    };

    return (
      <div className={clsx(Styles.inputWrapper, className)}>
        <input
          data-element="text-input"
          className={clsx(Styles.input, className)}
          disabled={disabled}
          id={id}
          onBlur={handleBlur}
          onFocus={onFocus}
          onChange={handleChange}
          type={type}
          {...inputProps}
          value={value}
          ref={ref}
        />
        {children}
      </div>
    );
  }
);

Input.displayName = 'TextInputInput';

/**
 * --------------------------------------------------------
 * TextInput Adornment
 * --------------------------------------------------------
 */
export type TextInputAdornmentProps = PropsWithChildren & {
  className?: string;
  align?: 'start' | 'end';
  style?: CSSProperties;
};

export const Adornment = (props: TextInputAdornmentProps) => {
  const { align = 'start', className, ...rest } = props;
  return (
    <div
      data-align={align}
      className={clsx(Styles.adornment({ align }), className)}
      {...rest}
    />
  );
};

Adornment.displayName = 'TextInputAdornment';
