import { isProduction } from '@studio/utils/env-utils';

export function getStatsigEnv() {
  // Statsig supports development, staging and production
  // Our application only supports development and production
  const tier = isProduction() ? 'production' : 'development';
  return {
    tier,
  };
}
