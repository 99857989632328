import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function AddToProjectIcon(props: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={clsx(iconStyles, props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.39374 17.0862C8.31977 18.122 9.09947 19.0216 10.1352 19.0956L18.043 19.6604C19.0788 19.7343 19.9784 18.9546 20.0524 17.9189L20.8349 6.96147C20.9089 5.92569 20.1292 5.02606 19.0934 4.95209L11.1857 4.38732C10.1499 4.31335 9.25028 5.09305 9.17631 6.12883L8.39374 17.0862ZM6.84953 19.4464C6.38009 18.7264 6.13338 17.8529 6.19933 16.9295L6.73097 9.48556L6.73097 9.48555L6.9819 5.97211C7.00048 5.71194 7.04306 5.45923 7.10733 5.21596L5.05447 5.31825C3.87728 5.3769 3.06817 6.38694 3.2185 7.43727L3.2185 7.43727L4.24543 14.6123L2.52956 18.1515L1.04069 7.74897C0.697353 5.3501 2.54222 3.2407 4.94499 3.12098L8.23666 2.95696C8.36349 2.95064 8.48638 2.96607 8.6017 2.99996C9.36067 2.42954 10.321 2.11997 11.3424 2.19291L19.2502 2.75768C21.4979 2.9182 23.1899 4.87048 23.0294 7.1182L22.2468 18.0756C22.0863 20.3233 20.134 22.0153 17.8863 21.8548L9.97852 21.29L9.938 21.2869C9.77997 21.3967 9.5911 21.4664 9.38514 21.4803L6.93082 21.6459C4.78064 21.7909 2.83598 20.2926 2.52956 18.1515L4.24543 14.6123L4.70737 17.8398L4.70737 17.8399C4.8433 18.7897 5.72851 19.522 6.78279 19.4509L6.84953 19.4464ZM4.24543 14.6123L4.24543 14.6123L6.73097 9.48556L4.24543 14.6123ZM14.5667 6.98891C15.1742 6.98891 15.6667 7.48139 15.6667 8.08891V10.9H18.4778C19.0853 10.9 19.5778 11.3925 19.5778 12C19.5778 12.6075 19.0853 13.1 18.4778 13.1H15.6667V15.9111C15.6667 16.5186 15.1742 17.0111 14.5667 17.0111C13.9592 17.0111 13.4667 16.5186 13.4667 15.9111V13.1L10.6556 13.1C10.048 13.1 9.55556 12.6075 9.55556 12C9.55556 11.3925 10.048 10.9 10.6556 10.9H13.4667V8.08891C13.4667 7.48139 13.9592 6.98891 14.5667 6.98891Z"
        fill="currentColor"
      />
    </svg>
  );
}
