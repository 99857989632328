import { create } from 'zustand';
import { trackEvent } from '@studio/lib/heap';

export type PageParams<T> = T[keyof T];
export type ParamOptions = { withHistory: boolean };
export type ParamsObject = Record<string, string>;

export type PageParamsStore = {
  params: ParamsObject;
  defaultParams: ParamsObject;
  setDefaults: (defaultParams: ParamsObject | undefined) => void;
  hydrateParams: () => void;
  removeParam: (key: string, options?: ParamOptions) => void;
  removeParamTracked: (
    eventName: string,
    key: string,
    options?: ParamOptions
  ) => void;
  resetParams: (excludeParams?: string[]) => void;
  setParams: (params: ParamsObject, options?: ParamOptions) => void;
  setParamsTracked: (
    eventName: string,
    params: ParamsObject,
    options?: ParamOptions
  ) => void;
  clearParams: () => void;
};

/**
 * Retrieves the current URL's search parameters (the query string).
 */
const getSearchParams = () => {
  return window.location.search;
};

/**
 * Updates the current URL's search parameters with the provided parameters.
 */
export const updateSearchParamsInUrl = (
  params: ParamsObject,
  options: ParamOptions = { withHistory: false }
) => {
  const searchParams = new URLSearchParams(params as Record<string, string>);
  const to = `${window.location.pathname}?${searchParams.toString()}`;

  if (options.withHistory) {
    window.history.pushState(null, '', to);
    return;
  }

  window.history.replaceState(null, '', to);
};

/**
 * Validates the incoming URL search parameters against a predefined set of valid parameters.
 *
 * @param {string} searchParams - The search parameters as a string.
 * @returns {ParamsObject} An object with validated and sanitized search parameters.
 */
export const validateIncomingSearchParams = (
  searchParams: string
): ParamsObject => {
  const params = new URLSearchParams(searchParams);
  const paramsObject: ParamsObject = {};

  for (const [key, value] of params.entries()) {
    paramsObject[key as keyof ParamsObject] = value;
  }

  return paramsObject;
};

export const useParamStore = create<PageParamsStore>()((set, get) => ({
  defaultParams: {},
  params: {},

  /**
   * Hydrates the store with valid keys from the window.location.search string.
   */
  hydrateParams: () => {
    const paramString =
      window.location.search === ''
        ? new URLSearchParams(
            get().defaultParams as Record<string, string>
          ).toString()
        : getSearchParams();

    const params = validateIncomingSearchParams(paramString);

    if (Object.keys(params).length > 0) {
      set({ params });
      updateSearchParamsInUrl(params);
    }
  },

  /**
   * Resets all parameters to their default values, except for those specified in excludeParams.
   * This function is particularly useful when needing to revert most of the application's state
   * to its initial configuration without affecting a subset of the state that's specified by the caller.
   *
   * @param {string[]} excludeParams - An array of parameter names to be excluded from resetting.
   * These parameters will retain their current values while all others are reset to their defaults.
   */
  resetParams: (excludeParams: string[] = []) => {
    // Get the current default parameters and the current parameters
    const defaultParams = get().defaultParams;
    const params = get().params;

    // Iterate over excludeParams to retain their current values in newParams
    excludeParams.forEach((param) => {
      if (params[param] !== undefined) {
        defaultParams[param] = params[param];
      }
    });

    updateSearchParamsInUrl(defaultParams);
    set({ params: defaultParams });
  },

  /**
   * Set and sync params to the URL
   */
  setParams: (newParams, options) => {
    const params = {
      ...get().params,
      ...newParams,
    };
    updateSearchParamsInUrl(params, options);
    set({ params });
  },

  /**
   * Track, set and sync params to the URL
   */
  setParamsTracked: (eventName, newParams, options) => {
    const params = {
      ...get().params,
      ...newParams,
    };
    trackEvent(eventName, params);
    updateSearchParamsInUrl(params, options);
    set({ params });
  },

  /**
   * Set default params in the store
   */
  setDefaults: (defaultParams: ParamsObject | undefined) => {
    if (defaultParams && Object.keys(defaultParams).length > 0) {
      set({ defaultParams });
    } else {
      set({ defaultParams: {}, params: {} });
      updateSearchParamsInUrl({});
    }
  },

  /**
   * Remove a given param and sync params to the URL
   */
  removeParam: (key, options) => {
    const newParams = { ...get().params };
    delete newParams[key];
    updateSearchParamsInUrl(newParams, options);
    set({ params: newParams });
  },

  /**
   * Remove a given param, sync params and track to the URL
   */
  removeParamTracked: (eventName, key, options) => {
    const newParams = { ...get().params };
    delete newParams[key];
    trackEvent(eventName, newParams);
    updateSearchParamsInUrl(newParams, options);
    set({ params: newParams });
  },

  /**
   * Clear out the params store and sync to the URL
   */
  clearParams: () => {
    const params = {};
    updateSearchParamsInUrl(params);
    set({ params });
  },
}));
