import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useProfileQuery } from '@studio/hooks';
import { trackEvent } from '@studio/lib/heap';
import { useOrganizationStore } from '@studio/stores';
import { Button, Text } from '@lib/ui';
import * as STRINGS from '../../constants';
import { usePartnerStackParams } from '../../hooks';
import * as Styles from './shared.css';

type ConnectAChannelButtonProps = {
  copy: string;
  trackEventType: string;
  className?: string;
};

export function ConnectAChannelButton({
  copy,
  trackEventType,
  className,
}: ConnectAChannelButtonProps) {
  const { t } = useTranslation();
  const { data, isFetching } = useProfileQuery();
  const { activeOrganizationId } = useOrganizationStore();
  const { utm_source } = usePartnerStackParams();

  const orgUser = data?.organizationUsers.find(
    (orgUser) => orgUser.organizationId === activeOrganizationId
  );

  const orgUserId = orgUser?.id;

  const handleSignUp = () => {
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      redirect_uri: import.meta.env.VITE_OAUTH_REDIRECT_URI,
      access_type: 'offline',
      scope:
        'https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly profile email',
      connection: 'google-oauth2',
      prompt: 'consent select_account',
      include_granted_scopes: 'true',
      enable_granular_consent: 'true',
      state: window.btoa(
        JSON.stringify({
          source: 'ST',
          organization_user_id: orgUserId,
          frontend_redirect: STRINGS.ONBOARDING_WELCOME,
          utm_source,
        })
      ),
    });
    trackEvent(trackEventType);
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?${params}`;
  };

  return (
    <Button
      disabled={!orgUserId}
      loading={isFetching}
      onClick={handleSignUp}
      pill
      className={clsx(Styles.button, className)}
      size="lg"
      variant="newPrimary"
    >
      <Trans t={t}>
        <Text size="16">{copy}</Text>
      </Trans>
    </Button>
  );
}
