export const subscription_signup_sign_in_with_google_click =
  'subscription_signup_sign_in_with_google_click';
export const subscription_signup_plan_toggle =
  'subscription_signup_plan_toggle';
export const subscription_signup_start_trial_click =
  'subscription_signup_start_trial_click';
export const subscription_signup_continue_redirect_click =
  'subscription_signup_continue_redirect_click';
export const subscription_signup_waitlist_click =
  'subscription_signup_waitlist_click';
export const youtube_auth_connect_now_click = 'youtube_auth_connect_now_click';
export const youtube_auth_new_channel_added = 'youtube_auth_new_channel_added';
export const youtube_auth_error_page_need_access =
  'youtube_auth_error_page_need_access';
