import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Icons, Text, TextArea } from '@lib/ui';
import { Flex } from '../layout';
import * as Styles from './ideation-element.css';

interface Props {
  onCancel: () => void;
  onChange?: (value: string) => void;
  onSubmit: (value: string) => void;
  setThisButValue: Dispatch<SetStateAction<string>>;
  thisButValue: string;
}

export const ThisButEditor = ({
  onChange,
  onCancel,
  onSubmit,
  setThisButValue,
  thisButValue,
}: Props) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const currentValue = event.target.value;
      setThisButValue(currentValue);
      onChange?.(currentValue);
    },
    [onChange]
  );

  const handleSubmit = useCallback(() => {
    if (thisButValue.length > 0) {
      onSubmit(thisButValue);
    }
  }, [thisButValue, onSubmit]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Flex className={Styles.thisBut} flexDirection="column">
      <TextArea
        className={Styles.editor}
        value={thisButValue}
        onChange={handleChange}
        fill="none"
        autoHeight
        autoFocus
        resize="none"
        border="none"
        placeholder={t('This, but...')}
        maxLength={1000}
        preventNewLine
        onKeyDown={handleKeyDown}
      />
      <Flex justifyContent="space-between" alignItems="center">
        <button
          onClick={onCancel}
          className={Styles.editorButton}
          aria-label={t('cancel prompt')}
        >
          <Text textDecoration="underline">{t('Cancel')}</Text>
        </button>
        <button
          disabled={thisButValue.length === 0}
          onClick={handleSubmit}
          className={Styles.editorSubmit}
          aria-label={t('submit prompt')}
        >
          <Icons.KeyboardArrowRightIcon aria-hidden />
        </button>
      </Flex>
    </Flex>
  );
};
