import { transparentize } from 'polished';
import { convertColorsToRGB } from './utils';

/**
 * Note: These mostly line up with YouTube's current breakpoints
 * with an allowance for our sidebar's expanded width
 */
export const breakpoints = {
  xs: '(width >= 800px)',
  sm: '(width >= 1200px)',
  md: '(width >= 1600px)',
  lg: '(width >= 1900px)',
  xl: '(width >= 2200px)',
} as const;

export const colors = {
  beige500: '#D1CCBD',
  beige600: '#C4BDA7',
  black: '#000000',
  black100: '#141414',
  black200: '#202020',
  black300: '#242424',
  black400: '#272727',
  black500: '#292929',
  black600: '#2E2E2E',
  black700: '#303030',
  black800: '#353535',
  black900: '#373737',
  black1000: '#3A3A3A',
  blue50: '#F0FAFE',
  blue150: '#7bb1ff',
  blue550: '#3976e2',
  blue300: '#50D3EF',
  cream100: '#FAFAF3',
  gray50: '#fAFAFA',
  gray100: '#F8F8F8',
  gray200: '#EEEEEE',
  gray300: '#d9d9d9',
  gray400: '#bdbdbd',
  gray500: '#9e9e9e',
  gray600: '#757575',
  gray700: '#616161',
  gray750: '#555555',
  gray800: '#404040',
  gray900: '#333333',
  gray1000: '#282828',
  green50: '#F5FCF8',
  green100: '#DFF5E8',
  green150: '#81d3c6',
  green200: '#B1F1CB',
  green250: '#6BE4AA',
  green300: '#3dd68c',
  green400: '#35B979',
  green500: '#30A46C',
  green550: '#08998e',
  green600: '#2C8C5E',
  green700: '#246b4b',
  green800: '#1E5E41',
  orange200: '#FFE0C2',
  orange300: '#FFA366',
  orange400: '#FF7F37',
  orange500: '#F76808',
  orange600: '#C36522',
  orange700: '#7E4318',
  purple100: '#F4F4FF',
  purple200: '#E3E4FF',
  purple250: '#D9DAFF',
  purple300: '#C5C7FA',
  purple400: '#8A8DF5',
  purple500: '#6F74F2',
  purple600: '#5040B4',
  red100: '#FFD1D9',
  red200: '#FF999C',
  red250: '#FF5A5F',
  red300: '#FF7478',
  red400: '#E5484D',
  red500: '#D21E24',
  red600: '#8B1D29',
  red700: '#691D25',
  red800: '#551C22',
  yellow300: '#FFE7B3',
  yellow400: '#FFD74C',
  yellow450: '#FFC032',
  yellow500: '#FFC600',
  yellow550: '#FFA70B',
  yellow600: '#A8762A',
  yellow650: '#CC8400',
  yellow700: '#6F4D1D',
  white: '#FFFFFF',
} as const;

export const colorsRGB = convertColorsToRGB(colors);

export const depths = {
  depth0: colors.black100,
  depth1: colors.black200,
  depth2: colors.black300,
  depth3: colors.black400,
  depth4: colors.black500,
  depth5: colors.black600,
  depth6: colors.black700,
  depth7: colors.black800,
  depth8: colors.black900,
  depth9: colors.black1000,
} as const;

export const depthsRGB = convertColorsToRGB(depths);

export const tints = {
  /** Black Tints */
  black100: 'rgba(0, 0, 0, .1)',
  black200: 'rgba(0, 0, 0, .2)',
  black300: 'rgba(0, 0, 0, .3)',
  black400: 'rgba(0, 0, 0, .4)',
  black500: 'rgba(0, 0, 0, .5)',
  black600: 'rgba(0, 0, 0, .6)',
  black700: 'rgba(0, 0, 0, .7)',
  black800: 'rgba(0, 0, 0, .8)',
  black900: 'rgba(0, 0, 0, .9)',
  /** Dark Tints */
  dark100: 'rgba(20, 20, 20, .1)',
  dark200: 'rgba(20, 20, 20, .2)',
  dark300: 'rgba(20, 20, 20, .3)',
  dark400: 'rgba(20, 20, 20, .4)',
  dark500: 'rgba(20, 20, 20, .5)',
  dark600: 'rgba(20, 20, 20, .6)',
  dark800: 'rgba(20, 20, 20, .8)',
  dark900: 'rgba(20, 20, 20, .9)',
  /** Light Tints */
  light100: 'rgba(250, 250, 250, .1)',
  light200: 'rgba(250, 250, 250, .2)',
  light300: 'rgba(250, 250, 250, .3)',
  light400: 'rgba(250, 250, 250, .4)',
  light500: 'rgba(250, 250, 250, .5)',
  /** White Tints */
  white100: 'rgba(255, 255, 255, .1)',
  white200: 'rgba(255, 255, 255, .2)',
  white300: 'rgba(255, 255, 255, .3)',
  white400: 'rgba(255, 255, 255, .4)',
  white500: 'rgba(255, 255, 255, .5)',
  white600: 'rgba(255, 255, 255, .6)',
  white700: 'rgba(255, 255, 255, .7)',
  white800: 'rgba(255, 255, 255, .8)',
  white900: 'rgba(255, 255, 255, .9)',
} as const;

export const fontFamilies = {
  body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  heading:
    '"TWK Lausanne", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
} as const;

export const fontWeights = {
  light: '200',
  normal: '400',
  medium: '500',
  bold: '700',
} as const;

export const fontScales = {
  /** 10px */
  text10: '0.625rem',
  /** 11px */
  text11: '0.6875rem',
  /** 12px */
  text12: '0.75rem',
  /** 13px */
  textDefault: '0.8125rem',
  /** 14px */
  text14: '0.875rem',
  /** 16px */
  text16: '1rem',
  /** 18px */
  text18: '1.125rem',
  /** 20px */
  text20: '1.25rem',
  /** 22px */
  text22: '1.375rem',
  /** 24px */
  text24: '1.5rem',
  /** 28px */
  text28: '1.75rem',
  /** 30px */
  text30: '1.875rem',
  /** 32px */
  text32: '2rem',
  /** 36px */
  text36: '2.25rem',
  /** 48px */
  text48: '3rem',
} as const;

export const lineHeights = {
  /** Line height 1 */
  l1: '1',
  /** Line height 1.2 */
  l2: '1.2',
  /** Line height 1.3 */
  l3: '1.3',
  /** Line height 1.4 */
  l4: '1.4',
} as const;

export const outlines = {
  focus: `2px solid ${tints.white200}`,
  light: `2px solid ${colors.white}`,
  minimal: `1px solid ${colors.white}`,
} as const;

export const radii = {
  /** 8px */
  default: '0.5rem',
  /** 0px */
  r0: '0',
  /** 2px */
  r02: '0.125rem',
  /** 4px */
  r04: '0.25rem',
  /** 6px */
  r06: '0.375rem',
  /** 8px */
  r08: '0.5rem',
  /** 10px */
  r10: '0.625rem',
  /** 12px */
  r12: '0.75rem',
  /** 14px */
  r14: '0.875rem',
  /** 16px */
  r16: '1rem',
  /** 18px */
  r18: '1.125rem',
  /** 20px */
  r20: '1.125rem',
  /** 24px */
  r24: '1.5rem',
  /** 28px */
  r28: '1.75rem',
  /** 9999px */
  pill: '9999px',
} as const;

export const scales = {
  /** 1px */
  s01: '1px',
  /** 2px */
  s02: '0.125rem',
  /** 4px */
  s03: '0.1875rem',
  /** 4px */
  s04: '0.25rem',
  /** 6px */
  s06: '0.375rem',
  /** 8px */
  s08: '0.5rem',
  /** 10px */
  s10: '0.625rem',
  /** 12px */
  s12: '0.75rem',
  /** 14px */
  s14: '0.875rem',
  /** 16px */
  s16: '1rem',
  /** 18px */
  s18: '1.125rem',
  /** 20px */
  s20: '1.25rem',
  /** 22px */
  s22: '1.375rem',
  /** 24px */
  s24: '1.5rem',
  /** 26px */
  s26: '1.625rem',
  /** 28px */
  s28: '1.75rem',
  /** 30px */
  s30: '1.875rem',
  /** 32px */
  s32: '2rem',
  /** 34px */
  s34: '2.125rem',
  /** 36px */
  s36: '2.25rem',
  /** 38px */
  s38: '2.375rem',
  /** 40px */
  s40: '2.5rem',
  /** 42px */
  s42: '2.625rem',
  /** 44px */
  s44: '2.75rem',
  /** 48px */
  s48: '3rem',
  /** 58px */
  s58: '3.625rem',
  /** 64px */
  s64: '4rem',
  /** 72px */
  s72: '4.5rem',
  /** 80px */
  s80: '5rem',
  /** 96px */
  s96: '6rem',
} as const;

export const sizes = {
  /** 50% */
  '1/2': '50%',
  /** 33.333% */
  '1/3': '33.333%',
  /** 66.666% */
  '2/3': '66.666%',
  /** 25% */
  '1/4': '25%',
  /** 75% */
  '3/4': '75%',
  /** 20% */
  '1/5': '20%',
  /** 40% */
  '2/5': '40%',
  /** 60% */
  '3/5': '60%',
  /** 80% */
  '4/5': '80%',
  /** 00% */
  full: '100%',
} as const;

export const easing = {
  /** Ease in: Quint */
  in: 'cubic-bezier(0.64, 0, 0.78, 0)',
  /** Ease out: Quint */
  out: 'cubic-bezier(0.22, 1, 0.36, 1)',
  /** Ease in out: Quint */
  inOut: 'cubic-bezier(0.83, 0, 0.17, 1)',
} as const;

export const timings = {
  in: {
    /** 250ms */
    short: '250ms',
    /** 300ms */
    medium: '300ms',
    /** 350ms */
    long: '350ms',
  },
  out: {
    /** 200ms */
    short: '200ms',
    /** 250ms */
    medium: '250ms',
    /** 300ms */
    long: '300ms',
  },
  inOut: {
    /** 250ms */
    short: '250ms',
    /** 300ms */
    medium: '300ms',
    /** 350ms */
    long: '350ms',
  },
};

export const transitions = {
  base: `all ${timings.out.short} ${easing.out}`,
  active: `all ${timings.inOut.short} ${easing.inOut}`,
} as const;

export const brand = {
  background: colors.black100,
  darkest: colors.black,
  dark: colors.black200,
  mildTransparent: transparentize('0.5', colors.black),
  darkTransparent: transparentize('0.7', colors.black200),
  light: colors.gray50,
  lightTransparent: transparentize('0.9', colors.gray50),
  primary: colors.yellow500,
  primaryAlt: colors.yellow400,
  primaryActive: colors.yellow300,
  primaryTransparent: transparentize('0.9', colors.yellow500),
  secondary: colors.gray400,
  secondaryAlt: colors.gray200,
  secondaryActive: colors.gray100,
  tertiary: colors.purple500,
  tertiaryAlt: colors.purple400,
  tertiaryActive: colors.purple300,
  success: colors.green500,
  successAlt: colors.green600,
  successActive: colors.green700,
  danger: colors.red400,
  dangerAlt: colors.red250,
  dangerActive: colors.red600,
  warning: colors.orange400,
  error: colors.red400,
  overlay: transparentize('0.3', colors.black),
} as const;

export const brandRGB = convertColorsToRGB(brand);

export const keyframes = {
  contentShow: {
    '0%': {
      opacity: '0',
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: '1',
      transform: 'translateX(0%)',
    },
  },
  explode: {
    '0%': { transform: 'rotate(0deg)' },
    '25%': { transform: 'rotate(-3deg) rotateY(10deg)' },
    '50%': { transform: 'rotate(0deg)' },
    '75%': { transform: 'rotate(3deg) rotateY(-10deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },
  dialogShow: {
    '0%': {
      opacity: '0',
      transform: 'translate(-50%, -48%) scale(0.96)',
    },
    '100%': {
      opacity: '1',
      transform: 'translate(-50%, -50%) scale(1)',
    },
  },
  overlayShow: {
    '0%': {
      opacity: '0',
    },
    '100%': {
      opacity: '1',
    },
  },
  spin: {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  slideUpAndFadeIn: {
    '0%': {
      opacity: '0',
      transform: `translateY(${scales.s04})`,
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
  },
  slideRightAndFadeIn: {
    '0%': {
      opacity: '0',
      transform: `translateX(-${scales.s04})`,
    },
    '100%': {
      opacity: '1',
      transform: 'translateX(0)',
    },
  },
  slideDownAndFadeIn: {
    '0%': {
      opacity: '0',
      transform: `translateY(-${scales.s04})`,
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
  },
  slideLeftAndFadeIn: {
    '0%': {
      opacity: '0',
      transform: `translateX(${scales.s04})`,
    },
    '100%': {
      opacity: '1',
      transform: 'translateX(0)',
    },
  },
  slideUpAndFadeOut: {
    '0%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
    '100%': {
      opacity: '0',
      transform: `translateY(-${scales.s04})`,
    },
  },
  slideRightAndFadeOut: {
    '0%': {
      opacity: '1',
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: '0',
      transform: `translateX(${scales.s04})`,
    },
  },
  slideDownAndFadeOut: {
    '0%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
    '100%': {
      opacity: '0',
      transform: `translateY(${scales.s04})`,
    },
  },
  slideLeftAndFadeOut: {
    '0%': {
      opacity: '1',
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: '0',
      transform: `translateX(-${scales.s04})`,
    },
  },
  shimmer: {
    '0%': { backgroundPosition: '-200% 0' },
    '100%': { backgroundPosition: '100% 0' },
  },
} as const;

export const zIndex = {
  z001: '1',
  z002: '2',
  z003: '3',
  z004: '4',
  z010: '10',
  z100: '100',
  z105: '105',
  z110: '110',
  z200: '200', // Main nav sidebar
  z250: '250', // Saved Items Panel
  z270: '270', // Dialog Overlay
  z280: '280', // Dialogs, Select, DropdownMenu
  z300: '300', // tooltip
  z999: '999', // toast
} as const;
