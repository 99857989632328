import { useQuery } from '@tanstack/react-query';
import { fetchStripePlanById } from '../requests';

export const useFetchStripePlanById = (planId: string) =>
  useQuery({
    queryKey: ['stripe-plan-by-id', planId],
    queryFn: () => {
      return fetchStripePlanById(planId);
    },
    refetchOnWindowFocus: false,
  });
