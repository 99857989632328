export const youtube_auth_error_page_need_access_connect_click =
  'youtube_auth_error_page_need_access_connect_click';
export const youtube_auth_error_page_unexpected_error =
  'youtube_auth_error_page_unexpected_error';
export const youtube_auth_error_page_unexpected_error_connect_click =
  'youtube_auth_error_page_unexpected_error_connect_click';
export const youtube_auth_error_page_empty_no_videos =
  'youtube_auth_error_page_empty_no_videos';
export const youtube_auth_error_page_empty_no_videos_connect_click =
  'youtube_auth_error_page_empty_no_videos_connect_click';
export const youtube_auth_error_page_empty_no_videos_cancel_subscription_click =
  'youtube_auth_error_page_empty_no_videos_cancel_subscription_click';
export const youtube_auth_error_page_empty_no_channels =
  'youtube_auth_error_page_empty_no_channels';
export const youtube_auth_error_page_empty_no_channels_connect_click =
  'youtube_auth_error_page_empty_no_channels_connect_click';
export const youtube_auth_error_page_empty_channel_limit_connect_click =
  'youtube_auth_error_page_empty_channel_limit_connect_click';
export const youtube_auth_error_page_empty_no_channels_cancel_subscription_click =
  'youtube_auth_error_page_empty_no_channels_cancel_subscription_click';
export const youtube_auth_error_page_empty_channel_limit_cancel_subscription_click =
  'youtube_auth_error_page_empty_channel_limit_cancel_subscription_click';
export const youtube_auth_error_page_already_auth =
  'youtube_auth_error_page_already_auth';
export const youtube_auth_error_page_already_auth_connect_click =
  'youtube_auth_error_page_already_auth_connect_click';
export const youtube_auth_error_page_already_auth_contact_click =
  'youtube_auth_error_page_already_auth_contact_click';
export const youtube_auth_welcome_get_started_click =
  'youtube_auth_welcome_get_started_click';
export const youtube_auth_error_page_404 = 'youtube_auth_error_page_404';
export const youtube_auth_error_page_404_back_home_click =
  'youtube_auth_error_page_404_back_home_click';
export const youtube_auth_error_page_500 = 'youtube_auth_error_page_500';
export const youtube_auth_error_page_500_refresh_click =
  'youtube_auth_error_page_500_refresh_click';
export const youtube_auth_error_page_500_contact_click =
  'youtube_auth_error_page_500_contact_click';
export const youtube_auth_error_page_maintenance =
  'youtube_auth_error_page_maintenance';
