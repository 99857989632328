import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';
import { Flex, Text, Button } from '@lib/ui';
import { useCurrencyFormatter } from '../../hooks';
import { PlanDiscount, PlanTrial, PlanType } from '../../types';
import * as Styles from './pricing-plan-card.css';
import { vars } from '@lib/theme';

type PricingPlanCardProps = {
  price: number;
  planType: PlanType;
  discount?: PlanDiscount;
  trial: PlanTrial | undefined;
  isProcessing?: boolean;
  onClick: (planType: PlanType) => void;
};

export const PricingPlanCard = ({
  price,
  planType,
  discount = null,
  trial,
  isProcessing = false,
  onClick,
}: PricingPlanCardProps) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencyFormatter();

  const isYearly = planType === PlanType.Yearly;

  const unDiscountedAnnualPrice =
    discount && isYearly
      ? calculateOriginalPrice(price, discount.percentage)
      : null;

  const handleClick = () => {
    return onClick(planType);
  };

  return (
    <div className={Styles.pricingPlanCard}>
      {discount?.percentage ? (
        <div className={Styles.saveLabel}>
          <Text color="black200" size="14" weight="bold">
            {t('Save')} {discount?.percentage}%
          </Text>
        </div>
      ) : null}
      <Flex
        flexDirection="column"
        gap={vars.scales.s16}
        className={clsx(Styles.pricingPlanCardContainer, {
          [Styles.focusContainer]: !isYearly,
        })}
      >
        <Flex className={Styles.pricingPlanCardHeader}>
          <Trans t={t}>
            <Text size="24" weight="bold">
              {isYearly ? 'Yearly' : 'Monthly'}
            </Text>
          </Trans>
        </Flex>
        <Flex flexDirection="column" className={Styles.pricingPlanCardBody}>
          <Flex flexDirection="column" gap="8px" padding="16px">
            <Flex alignItems="center" gap="8px" height="48px">
              {discount && unDiscountedAnnualPrice ? (
                <p className={Styles.discountedPrice}>
                  {formatCurrency(unDiscountedAnnualPrice)}
                </p>
              ) : null}
              <Text as="p" size="48" color="yellow550" weight="bold">
                {formatCurrency(price)}
              </Text>
              <Flex flexDirection="column">
                <Trans t={t}>
                  <Text as="p" size="18" color="gray400">
                    USD
                  </Text>
                  <Text as="p" size="18" color="gray400">
                    {isYearly ? '/yr' : '/mo'}
                  </Text>
                </Trans>
              </Flex>
            </Flex>
            {discount ? (
              <Trans t={t}>
                <Text size="18" color="green150" weight="normal">
                  Limited time offer
                </Text>
              </Trans>
            ) : null}
          </Flex>
          <Button
            disabled={isProcessing}
            fill={isYearly ? 'ghost' : 'solid'}
            pill
            size="lg"
            onClick={handleClick}
            variant="newPrimary"
          >
            <Trans t={t}>
              {trial && trial?.active && trial.period
                ? `Start my ${trial.period}-day free trial`
                : 'Subscribe'}
            </Trans>
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};

function calculateOriginalPrice(
  discountedPrice: number,
  discountPercentage: number
): number {
  const originalPrice = discountedPrice / (1 - discountPercentage / 100);
  return parseFloat(originalPrice.toFixed(2));
}
