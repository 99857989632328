import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function TeamIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={clsx(iconStyles, props.className)}
    >
      <path
        d="M12.0833 7.08334C12.0833 6.53081 11.8638 6.00091 11.4731 5.6102C11.0824 5.2195 10.5525 5.00001 10 5.00001C9.44746 5.00001 8.91756 5.2195 8.52686 5.6102C8.13616 6.00091 7.91667 6.53081 7.91667 7.08334V7.91668C7.91667 8.46921 8.13616 8.99912 8.52686 9.38982C8.91756 9.78052 9.44746 10 10 10C10.5525 10 11.0824 9.78052 11.4731 9.38982C11.8638 8.99912 12.0833 8.46921 12.0833 7.91668V7.08334ZM5.83333 14.5833V16.25C5.83333 16.3605 5.87723 16.4665 5.95537 16.5446C6.03351 16.6228 6.13949 16.6667 6.25 16.6667H13.75C13.8605 16.6667 13.9665 16.6228 14.0446 16.5446C14.1228 16.4665 14.1667 16.3605 14.1667 16.25V14.5833C14.1656 13.5891 13.7701 12.6359 13.0671 11.9329C12.3641 11.2299 11.4109 10.8344 10.4167 10.8333H9.58333C8.58911 10.8344 7.63592 11.2299 6.9329 11.9329C6.22988 12.6359 5.83444 13.5891 5.83333 14.5833ZM5 8.33334C5.55233 8.33268 6.08185 8.11298 6.47241 7.72242C6.86297 7.33186 7.08267 6.80234 7.08333 6.25001V5.41668C7.08333 4.86414 6.86384 4.33424 6.47314 3.94354C6.08244 3.55284 5.55253 3.33334 5 3.33334C4.44746 3.33334 3.91756 3.55284 3.52686 3.94354C3.13616 4.33424 2.91667 4.86414 2.91667 5.41668V6.25001C2.91733 6.80234 3.13703 7.33186 3.52759 7.72242C3.91815 8.11298 4.44767 8.33268 5 8.33334Z"
        fill="currentColor"
      />
      <path
        d="M5 14.5833C5.00016 13.6347 5.29488 12.7094 5.84346 11.9354C6.39205 11.1615 7.16742 10.5769 8.0625 10.2625C7.36072 9.5609 6.40902 9.16673 5.41667 9.16668H4.58333C3.58925 9.16822 2.63631 9.5638 1.93339 10.2667C1.23046 10.9697 0.834877 11.9226 0.833334 12.9167V14.5833C0.833334 14.6939 0.877233 14.7998 0.955373 14.878C1.03351 14.9561 1.13949 15 1.25 15H5V14.5833ZM15 8.33334C15.5523 8.33268 16.0819 8.11298 16.4724 7.72242C16.863 7.33186 17.0827 6.80234 17.0833 6.25001V5.41668C17.0833 4.86414 16.8638 4.33424 16.4731 3.94354C16.0824 3.55284 15.5525 3.33334 15 3.33334C14.4475 3.33334 13.9176 3.55284 13.5269 3.94354C13.1362 4.33424 12.9167 4.86414 12.9167 5.41668V6.25001C12.9173 6.80234 13.137 7.33186 13.5276 7.72242C13.9182 8.11298 14.4477 8.33268 15 8.33334ZM15.4167 9.16668H14.5833C13.591 9.16673 12.6393 9.5609 11.9375 10.2625C12.8326 10.5769 13.608 11.1615 14.1565 11.9354C14.7051 12.7094 14.9998 13.6347 15 14.5833V15H18.75C18.8605 15 18.9665 14.9561 19.0446 14.878C19.1228 14.7998 19.1667 14.6939 19.1667 14.5833V12.9167C19.1651 11.9226 18.7695 10.9697 18.0666 10.2667C17.3637 9.5638 16.4108 9.16822 15.4167 9.16668Z"
        fill="currentColor"
      />
    </svg>
  );
}
